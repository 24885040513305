@import "~antd/dist/antd.less";

@font-face {
  font-family: "Poppins-Bold";
  src: url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}

:root {
  --primary-color: #1cb2bb;
  --text-color: #293b47;
}

body {
  font-family: "Poppins-Regular";
}

// Typography

h1 {
  font-family: "Poppins-Bold";
  font-size: 48px;
}

// Text Input
// .ant-input-affix-wrapper {
//   // padding: 12px 16px;
//   .ant-input-prefix {
//     // margin-right: 12px;
//   }
//   &:not(.ant-input-affix-wrapper-disabled):hover {
//     border-color: var(--primary-color);
//     border-right-width: 1px !important;
//   }
//   &:focus,
//   &-focused {
//     border-color: var(--primary-color);
//     border-right-width: 1px !important;
//     box-shadow: none;
//   }
// }

// Checkbox
// .ant-checkbox-wrapper:hover .ant-checkbox-inner,
// .ant-checkbox:hover .ant-checkbox-inner,
// .ant-checkbox-input:focus + .ant-checkbox-inner {
//   border-color: var(--primary-color);
// }

// .ant-checkbox-checked {
//   & .ant-checkbox-inner {
//     background-color: var(--primary-color);
//     border-color: var(--primary-color);
//   }
//   &::after {
//     border: 1px solid var(--primary-color);
//   }
// }

// Select
// .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
//   border-color: var(--primary-color);
// }

// Picker
// .ant-picker {
//   &-focused,
//   &:hover,
//   &:active,
//   &:focus {
//     border-color: var(--primary-color);
//     box-shadow: none;
//   }
// }

// Button
// .ant-btn {
//   // padding: 12px 11px;
//   height: auto;
//   border-radius: 4px;
//   &-primary {
//     background: var(--primary-color);
//     border-color: var(--primary-color);
//     &:hover,
//     &:focus {
//       background: var(--primary-color);
//       border-color: var(--primary-color);
//       opacity: 0.7;
//     }
//   }
//   &:active,
//   &:hover,
//   &:focus {
//     border-color: var(--primary-color);
//     color: var(--primary-color);
//   }
// }

// Links
a {
  font-family: "Poppins-Medium";
  color: var(--text-color);

  &:hover {
    color: var(--primary-color);
  }
}

///////////////////////////

.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

// .site-layout {
//   background: #ffffff;
// }

// .site-layout-background {
//   background: #ffffff;
// }

// .site-layout-header {
//   background: #ffffff;
//   z-index: 10;
//   -webkit-box-shadow: 0 2px 8px #f0f1f2;
//   box-shadow: 0 2px 8px #f0f1f2;
//   line-height: 20px;
// }

// .ant-layout-sider-children {
//   border-right: 1px solid #f0f0f0;
// }

// .ant-menu-inline {
//   border-right: 0;
// }

// .ant-menu-submenu-selected {
//   color: var(--primary-color);
// }

// .ant-divider-horizontal {
//   display: -ms-flexbox;
//   display: flex;
//   clear: both;
//   width: 100%;
//   min-width: 100%;
//   margin: 5px 0 !important;
// }

// .nav-bar-profile-menu {
//   top: 65px !important;
// }

.ant-layout-sider-trigger {
  background: #ddd !important;
}

.ant-layout-sider-trigger:hover {
  background: #ccc !important;
}

// .card-style {
//   background: #ffffff;
//   z-index: 10;
//   -webkit-box-shadow: 0 2px 8px #f0f1f2;
//   box-shadow: 0 2px 8px #f0f1f2;
// }

// .card-style .ant-card-body {
//   padding: 7px 22px 22px 22px !important;
// }

// .table-col-capitalize {
//   text-transform: capitalize;
// }


.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
@primary-color: #1cb2bb;