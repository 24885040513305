.select_box {
    width: 100%;
    cursor: pointer;
}
.print-button:hover {
    background-color: #3c096c;
    color: #ffffff;
    cursor: pointer;
}
.print-button {
    background-color: #084087;
    color: #ffffff;
    cursor: pointer;
}
.select-box-col {
    margin-bottom: 5px;
}
.card-design-blue {
    height: 100%;
    border-bottom: 7px solid #007fd4;
    background-color: #ffffff;
    z-index: 10;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px #f0f1f2;
}
.card-design-violet {
    height: 100%;
    border-bottom: 7px solid #3c096c;
    background-color: #ffffff;
    z-index: 10;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px #f0f1f2;
}
.card-design-green {
    height: 100%;
    border-bottom: 7px solid #344e41;
    background-color: #ffffff;
    z-index: 10;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px #f0f1f2;
}
.card-design-dark-blue {
    height: 100%;
    border-bottom: 7px solid #084087;
    background-color: #ffffff;
    z-index: 10;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px #f0f1f2;
}
.graph-style {
    background: #ffffff;
    z-index: 10;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px #f0f1f2;
    margin-top: 75px;
    padding: 20px;
    margin-bottom: 30px;
}

.graph-style .ant-card-body {
    padding: 7px 22px 22px 22px !important;
}
.table-style {
    background: #ffffff;
    z-index: 10;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 15px #f0f1f2;
    padding: 10px;
    margin-top: 50px;
}
.table-title {
    font-weight: bold;
    color: #323437;
}
.row-style {
    margin-right: 30px;
}
.row-main-title {
    font-size: 130%;
    color: #323437;
}
.row-main-title-blue {
    font-size: 190%;
    color: #007fd4;
    font-weight: bold;
}
.row-main-title-violet {
    font-size: 190%;
    color: #3c096c;
    font-weight: bold;
}
.row-main-title-green {
    font-size: 190%;
    color: #344e41;
    font-weight: bold;
}
.row-main-title-dark-blue {
    font-size: 190%;
    color: #084087;
    font-weight: bold;
}
.row-title {
    font-size: 100%;
    color: #323437;
}
.row-title-bold {
    font-size: 100%;
    color: #323437;
    font-weight: bold;
}
